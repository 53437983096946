// Translated
// Migrated to script setup

<template lang="pug">
Alert.mt-3(
  v-if="success"
  type="success"
  data-i18n="newsletterSuccess"
) {{ t('newsletterSuccess') }}
form.d-flex.flex-column.w-100(
  v-else,
  @submit.prevent="registerEmail"
)
  Alert.mt-3(
    v-if="error"
    type="error"
  ) {{ error }}
  .row
    .col-12.form-group(v-if="useAirports")
      b-skeleton(
        v-if="loading"
        height="50px"
      )
      template(v-else)
        label.mr-3(
          for="airport"
          data-i18n="newsletterPopup.airport"
        ) {{ t('newsletterPopup.airport') }}
        select#airport.form-control(v-model="airport")
          option(
            v-for="ap in newsletterAirports",
            :key="ap.id",
            :value="ap.id"
          ) {{ ap.name }}

    .col-12.form-group
        label(
          for="subscribe-email"
          data-i18n="newsletterPopup.email"
        ) {{ t('newsletterPopup.email') }}
        .d-flex.flex-column.flex-md-row
          b-skeleton(
            v-if="loading"
            width="75%"
            height="50px"
          )
          template(v-else)
            input#subscribe-email.form-control(
              v-model="email"
              type="email",
              autocomplete="email"
            )
          b-skeleton.ml-3(
            v-if="loading"
            width="20%"
            height="50px"
          )
          template(v-else)
            button.btn.btn-lg.btn-bold.mt-3.mt-md-0.ml-md-3.newsletter-form-register-btn(
              type="submit"
              :class="$isSol ? 'btn-orange' : 'btn-blue'",
            data-i18n="newsletterSubscribeButton"
          ) {{ t('newsletterSubscribeButton') }}
</template>

<script setup>
import Alert from '@layers/web/components/alerts/Alert'

import localStorageKeys from '@layers/web/constants/local-storage-keys'

const localeStore = useLocaleStore()
const rootStore = useRootStore()
const { t } = useI18n()
const { trackNewsletterSignup } = useTracking()

const props = defineProps({
  horizontal: {
    type: Boolean,
    default: false,
  },

  campaign: {
    type: String,
    default: undefined,
  },
})

const email = ref('')
const airport = ref(undefined)
const loading = ref(false)
const error = ref(false)
const success = ref(false)

const { newsletterAirports } = storeToRefs(rootStore)
const { locale } = storeToRefs(localeStore)

// const vertical = computed(() => {
//   return !horizontal
// }) not used

const useAirports = computed(() => {
  return isSol() && (newsletterAirports.value || []).length > 0
})

onMounted(() => {
  if (isSol()) {
    expand()
  }
})

const expand = (async () => {
  loading.value = true

  if (!newsletterAirports.value?.length) {
    await rootStore.fetchNewsletterLists()
  }

  airport.value = newsletterAirports.value?.[0]?.id

  loading.value = false
})

const registerEmail = (async () => {
  error.value = false

  if (!email.value) {
    error.value = t('formFieldRequiredError')
    return
  }

  // Gtag event
  trackNewsletterSignup(airport.value)

  try {
    const { data: result, error } = await apiFetch(`/${locale.value}/newsletter-register`, {
      method: 'POST',
      body: {
        email: email.value,
        airpiort: airport.value, // yes, it's a typo in the endpoint
        campaign: props.campaign,
      },
    })
    if (error) {
      error.value = error.message

      return false
    }

    if (result) {
      try {
        localStorage.setItem(localStorageKeys.hideNewsletter, true)
      } catch (e) {
        // ignore
      }

      success.value = true
    }
  } catch (error) {
    error.value = error.data?.message || t('serverError')
  }
})
</script>

<style lang="scss" scoped>
.newsletter-form {
  &-register-btn {
    border-radius: 18px !important;
  }
}
</style>
